import axios from "axios";
import { useEffect, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL

export const Alerts = () => {

    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.post(
                
                `${API_URL}/module/alerts`,
                {}, // passando um corpo vazio, caso não precise enviar nenhum dado no corpo da requisição
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                
            );
            console.log('result ', result)
            setAlerts(result.data);
        };

        fetchData();
    }, []);

    
    return (
        <div>
          {alerts && alerts.length > 0 ? (
            alerts.map((alert: any, index: number) => (
              <div key={alert.id} className="alert alert-dismissible bg-light-warning d-flex flex-column flex-sm-row w-100 p-5 mb-10">
                <KTSVG className="svg-icon svg-icon-2x svg-icon-warning" path="/media/icons/duotune/general/gen007.svg" />
      
                <div className="d-flex flex-column pe-0 pe-sm-10">
                  <h4 className="fw-semibold">{alert?.title || ""}</h4>
                  <span>{alert?.message || ""}.</span>
                </div>
      
                <button
                  type="button"
                  className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                  data-bs-dismiss="alert"
                >
                  <KTSVG path="/media/icons/duotune/abstract/abs012.svg" />
                </button>
              </div>
            ))
          ) : (
            <div className="alert alert-dismissible bg-light-info d-flex flex-column flex-sm-row w-100 p-5 mb-10">
                <KTSVG className="svg-icon svg-icon-2x svg-icon-info" path="/media/icons/duotune/general/gen007.svg" />
      
                <div className="d-flex flex-column pe-0 pe-sm-10">
                  <h4 className="fw-semibold">Nenhum novo Alerta!</h4>
                  <span>Verificamos, e tudo está nos conformes.</span>
                </div>
      
                <button
                  type="button"
                  className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                  data-bs-dismiss="alert"
                >
                  <KTSVG path="/media/icons/duotune/abstract/abs012.svg" />
                </button>
            </div>
          )}
        </div>
      );
      
}