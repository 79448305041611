import axios, { AxiosError, AxiosResponse } from 'axios'
import { IDadosGerais, ICarne, ITypeInstallment, IInstallment, IFormContract } from '../interface/I_Contract'
import { IClient, IParticipant } from '../interface/I_Client';
import { IResponse, AuthType} from '../interface/I_General';
import { useAuth } from '../modules/auth';
import { ChangeInMass } from '../types/T_General';
import BaseServiceHttp from '../../_metronic/helpers/functions/http/BaseServiceHttp';

const DATA_GENERAL_URL = `${process.env.REACT_APP_API_URL}/module/details`;
const API_URL = process.env.REACT_APP_API_URL;
 
// recupera detalhes do contarto
export function detailsContract(id: number)
{
    return axios.post<IDadosGerais|any>(`${API_URL}/module/details`,{id});
}

// recupera os carner do contrato
export async function getMeatContract(id: number) 
{
    const customConfig:any = {
        method: 'get',
        url: `/module/getMeatContract`,
        data: {
            id: id,
        },
        
    };
    const http = BaseServiceHttp(customConfig);

    return await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            
                            return {
                                data:[]
                            }
                        }); 
    // return axios.get<ICarne>(`${API_URL}/module/getMeatContract`, { params: { id } });
}

// recupera dados do participante
export async function getParticipant(id: number) 
{
    try {
        const response = await axios.post<IParticipant>(`${API_URL}/module/getParticipant`, { params: { id } });
        // Tratar a resposta aqui
        console.log("Dados do participante:", response);
        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar os dados do participante:", error);
        throw error;
    }

}

// recupera dados do tipo de parcela
export async function getTypeInstallment(id: number | string, auth: AuthType) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/getTypeInstallment`,
        data: {
            id:id,
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }
                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao buscar condições de pagamentos'
                                }
                            }
                        });
    return response; 

}

// recupera dados do tipo de parcela
export async function getInstallment(id: number) 
{
    try {
        const response = await axios.get<IInstallment>(`${API_URL}/module/getInstallment`, { params: { id } });
        // Tratar a resposta aqui
        return response;
    } catch (error) {
        // Tratar o erro aqui
        // console.log("Ocorreu um erro ao buscar os dados tipo de parcela:", error);
        throw error;
    }

}


// inseri um contrato
export async function insertContractAPI(form: IFormContract, auth: AuthType) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/insertContract`,
        data: {
            form:form,
        },       
        
    };
    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }
                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar criar o contrato'
                                }
                            }
                        });
    return response;

}  


// editar um contrato
export async function editContractAPI(form: IFormContract, auth: AuthType) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/editContract`,
        data: {
            form,
        },
        
        
    };
    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }
                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar editar contrato'
                                }
                            }
                        });
    return response;

}  

// deleta uma ou mais contratos
export async function deleteContractAPI(ids: string[], auth: AuthType) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/deleteContract`,
        data: {
            ids,
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }
                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar deletar contrato'
                                }
                            }
                        });
    return response;
}

// altera dados da parcela em massa
export async function changeParcelInMassaApi(ids: string[], data: ChangeInMass[], auth: AuthType) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/changeParcelInMassa`,
        data: {
            ids,
            data
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }

                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar atualizar parcelas em massa'
                                }
                            }
                        });
    return response;

}

// altera contratos em massa
export async function changeContractInMassaApi(ids: string[], data: ChangeInMass[], auth: AuthType) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/changeContractInMassa`,
        data: {
            ids,
            data
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }

                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar atualizar contratos em massa'
                                }
                            }
                        });
    return response;

}

export const getContractByName = async(name: string, auth: AuthType) => {

    
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/getContractByName`,
                            { name },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}


// faz consulta em bsca do boleto
export async function queryBoleto(token_boleto: string, auth: AuthType) 
{

    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/queryBoleto`,
                            { token_boleto },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}