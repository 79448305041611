import React, { useEffect, useState } from "react";
import { useMutation } from 'react-query';
import { KTSVG } from "../../../../helpers";
import Select from "react-select";
import { FormProps, FormValues } from "../../../../../app/interface/I_Parcel";
import { NumericFormat } from "react-number-format";
import { Option } from "../../../../../app/interface/I_General";
import { optionsStatusParcel, optionsTypePayment } from "../../../../../app/globals/OptionsGlobals";
import Swal from "sweetalert2";

export const FormEditParcel = ({ 
    closeModal,
    handlerSaveForm,
    formValues,
    handleChange 
}: FormProps) => {
    
    // const filteredOptions = formValues.token_boleto !== ''
    //     ? statusParcelBoleto.filter(option => option.value === "0" || option.value === "1" || option.value === "2")
    //     : statusParcelBoleto;

    const [selectedFormPayment, setSelectedFormPayment] = useState<Option | null>(
        formValues.form_payment ? { value: formValues.form_payment, label: formValues.form_payment } : null
    );

    const [previousFormPaymants, setPreviousFormPaymats] = useState(formValues.form_payment)

    useEffect(() => {
        const formattedValue = formValues.value;
        handleChange("value", formattedValue);
    }, []);

    useEffect(() => {
        if(!!formValues.value_paid)
        {
            const formattedValuePaid = formValues.value_paid;
            handleChange("value_paid", formattedValuePaid);
        }
    }, []);

    
    const handleInputChange = (
        value: string|number,
        fieldName: keyof FormValues
      ) => {
        
        handleChange(fieldName, value);
    };
    
    const handleSelectChangeFormPayment = <K extends keyof FormValues>(selectedOption: Option | null,
        fieldName: K) => {
        const value = selectedOption ? selectedOption.value.trim() : "";
        
        // valor setado no carregamento (salvo no banco)
        if(formValues.form_payment !== 'Boleto')
        {
            // quando selecionado
            if(value === 'Boleto' && previousFormPaymants !== 'Boleto')
            {
                Swal.fire({
                    // title: 'Atenção',
                    icon: 'info',
                    // html:`Você selecionou <strong><u>${value}</u></strong>, deseja gerar um boleto para esta parcela?`,
                    html:`<div><p style="padding: 3px;"> <span style="background: #f5f0ff;padding: 3px;border-radius: 3px;">Você selecionou a opção <strong><u>${value}</u></strong><span></p></div>
                    <p>
                        <div style="text-align: center;">
                            <strong>Atenção!</strong>
                        </div>
                    </p>

                    <p>
                        <div style="text-align: justify;">
                            Caso necessite gerar o título de forma automática, por favor clique em <strong><u>Cancelar</u></strong> e em seguida <strong><u>cancele/remova</u></strong> sta parcela e gere outra condição de pagamento. 
                            Caso deseje somente informar o pagamento via boleto já gerado anteriormente, clique em "continuar". 
                        </div>
                    </p>
                    `,
                    showCloseButton: true,
                    showCancelButton: true,
                    // showDenyButton:true,
                    focusConfirm: false,
                    width: '800px',
                    confirmButtonText:'Continuar',
                    // denyButtonText: 'Cancelar'
                    cancelButtonText:'Cancelar'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed)
                    {
                        // handleChange('boleto', 'sim');
                        
                        handleChange(fieldName, value);
                        setSelectedFormPayment(selectedOption);
                    }
                    // else if (result.isDenied) {                        
                        handleChange('boleto', 'nao');
                    // }
                })
            }else{
                handleChange(fieldName, value);
                setSelectedFormPayment(selectedOption);
            }
        }else{

            let texto_api = ``;
            if(formValues.name_integration === 'api_cloud')
            {      
                texto_api = `Você deseja alterar a forma de pagamento de <strong><u>Boleto</u></strong> para <strong><u>${value}</u></strong>? Favor dar baixa manual no aplicativo do banco.`;         
                
            }else{
                texto_api = `Você deseja alterar a forma de pagamento de <strong><u>Boleto</u></strong> para <strong><u>${value}</u></strong>? Será dado baixa forçada pelo sistema.`; 
            }
            
            if(formValues.token_boleto !== '')
            {
                Swal.fire({
                    title: 'Atenção',
                    icon: 'info',
                    html: texto_api,
                    showCloseButton: true,
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText:'Sim, alterar',
                    cancelButtonText:'Não'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {                  
                       
                        setSelectedFormPayment(selectedOption);
                        handleChange(fieldName, value);
                        handleChange('baixa', 'sim');
                    }
                })
            }else{                
                
                setSelectedFormPayment(selectedOption);
                handleChange(fieldName, value);
            }
            
        }
    }

    const handleSelectChange = <K extends keyof FormValues>(selectedOption: Option | null,
        fieldName: K) => {
        const value = selectedOption ? selectedOption.value.trim() : "";
        const label = selectedOption ? selectedOption.label : "";

        if( (fieldName === 'status') && (value === '1' || value === '2') && (formValues.form_payment === 'Boleto') )
        {
            Swal.fire({
                // title: 'Atenção',
                icon: 'info',
                // html:`Você selecionou <strong><u>${value}</u></strong>, deseja gerar um boleto para esta parcela?`,
                html:`<div><p style="padding: 3px;"> <span style="background: #f5f0ff;padding: 3px;border-radius: 3px;">Você selecionou a opção <strong><u>${label}</u></strong><span></p></div>
                    <p>
                        <div style="text-align: center;">
                            <strong>Atenção!</strong>
                        </div>
                    </p>
                    <p>
                        <div style="text-align: justify;">
                            Caso exista boletos do itaú será dado baixa forçada automáticamente pelo sistema.
                        </div>
                    </p>
                    `,
                showCloseButton: true,
                showCancelButton: true,
                // showDenyButton:true,
                focusConfirm: false,
                width: '500px',
                confirmButtonText:'Continuar',
                // denyButtonText: 'Cancelar'
                cancelButtonText:'Cancelar'
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed)
                {
                    handleChange(fieldName, value);
                    handleChange('baixa', 'sim');
                }
            })

        }else{
            handleChange(fieldName, value);
        }
    };    

    return (
            <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal? "": "show"}`} id="modalEditParcel" tabIndex={-1} aria-modal="true" role="dialog" style={{"display": !closeModal? "none": 'block'}}>
                <div className="modal-dialog modal-xl p-5 p-lg-10" id="">
                    <div className="modal-content rounded-4">
                        <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                            <div id="" className="position-relative z-index-1"></div>

                            <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                                <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg"/>
                            </div>
                        </div>

                        <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="modaBody">
                            <div className="container-fluid">
                                <div className="d-block" id="kt_app_engage_prebuilts_view_image">
                                    <div className="tab-content">
                                        <div className="pt-5 tab-pane fade active show" id="" role="tabpanel">
                                            <div className="separator d-flex flex-center">
                                                <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Dados da Parcela</span>
                                            </div>
                                            <div className="pe-12 me-n12 mt-10">
                                                {
                                                    typeof formValues.token_boleto !== 'undefined' && formValues.token_boleto !== '' 
                                                    ?   
                                                        <>

                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                                                                {/* <i class="ki-duotone ki-design-1 fs-2tx text-primary me-4"></i> */}
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-semibold">
                                                                        <div className="fs-6 text-gray-700">
                                                                            <p>
                                                                                <div style={{textAlign: 'center'}}>
                                                                                    <strong>Atenção!</strong>
                                                                                </div>
                                                                            </p>
                                                            
                                                                            <p>
                                                                                <div style={{textAlign: 'justify'}}>
                                                                                    Somente boletos do banco <strong><u>Itaú</u></strong> sofrerão as alterações de forma simultânea no banco via API. 
                                                                                    Todos os outros deverão ser alterados também no aplicativo do respectivo banco. 
                                                                                </div>
                                                                            </p>
                                                                            <p>
                                                                                <div style={{textAlign: 'justify'}}>
                                                                                    Obs.: Ao lado de cada parcela com boleto gerado, segue também a bandeira do banco de origem, favor se atentar. 
                                                                                </div>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                            <div className="d-flex flex-end mb-5"><span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">{formValues.name_integration === 'api_cloud' ? 'INTEGRAÇÃO BOLETO CLOUD' : 'INTEGRAÇÃO ITAÚ'}</span></div>
                                                        </>
                                                    : <></>
                                                }
                                                
                                                <div className="row gy-5">
                                                    <div className="col-lg-4  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Forma de pargamento</label>
                                                        <div className='d-flex'>
                                                            <Select
                                                                styles={{ container: (base) => ({ ...base, zIndex: 99 }) }}
                                                                className="w-450px"
                                                                placeholder="Forma de pargamento"
                                                                value={selectedFormPayment}
                                                                // value={formValues.form_payment ? { value: formValues.form_payment, label: formValues.form_payment } : null}
                                                                onChange={(selectedOption) => handleSelectChangeFormPayment(selectedOption as Option | null, "form_payment")}
                                                                options={optionsTypePayment}
                                                                // isDisabled={formValues.token_boleto !== '' ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Vencimento</label>
                                                        <div className='d-flex'>
                                                            <input 
                                                                type="date" 
                                                                className={`form-control`} 
                                                                name="due_date"
                                                                value={formValues.due_date}
                                                                onChange={(e) => {
                                                                        handleInputChange(e.target.value, "due_date")
                                                                    }
                                                                }
                                                                // readOnly={formValues.token_boleto !== '' ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Valor</label>
                                                        <div className='d-flex'>
                                                            <NumericFormat
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix="R$ "
                                                                thousandsGroupStyle="thousand"
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                allowLeadingZeros={true}
                                                                // className={`form-control ${typeof formValues.token_boleto !== 'undefined' && formValues.token_boleto !== '' && formValues.name_integration != "itau_bank"  ? 'form-control-solid' : ''}`} 
                                                                className={`form-control`} 
                                                                name="value"
                                                                value={formValues.value || '0,00'}
                                                                onValueChange={(values) => {
                                                                    handleInputChange(values.floatValue || 0, "value");
                                                                  }}
                                                                // readOnly={typeof formValues.token_boleto !== 'undefined' && formValues.token_boleto !== '' && formValues.name_integration != "itau_bank" ? true : false}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Data de pagamento</label>
                                                        <div className='d-flex'>
                                                            <input 
                                                                type="date" 
                                                                className={`form-control`} 
                                                                name="payment_date"
                                                                value={formValues.payment_date}
                                                                onChange={(e) => {
                                                                        handleInputChange(e.target.value, "payment_date")
                                                                    }
                                                                }
                                                                // readOnly={formValues.token_boleto !== '' ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-lg-4  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Valor pago</label>
                                                        <div className='d-flex'>
                                                            <NumericFormat
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix="R$ "
                                                                thousandsGroupStyle="thousand"
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                allowLeadingZeros={true}
                                                                className={`form-control`} 
                                                                name="value_paid"
                                                                value={formValues.value_paid || '0,00'}
                                                                onValueChange={(values) => {
                                                                    handleInputChange(values.floatValue || 0, "value_paid");
                                                                }}
                                                                
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Situação</label>
                                                        <div className='d-flex'>
                                                            <Select
                                                                styles={{ container: (base) => ({ ...base, zIndex: 99 }) }}
                                                                className="w-450px"
                                                                placeholder="Selecione uma situação"
                                                                value={{ value: formValues.status, label: optionsStatusParcel.find(option => option.value === formValues.status)?.label }}
                                                                // value={formValues.status !== '' ? { value: formValues.status, label: filteredOptions.find(option => option.value === formValues.status)?.label } : null}
                                                                onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "status")}
                                                                options={optionsStatusParcel}
                                                            />
                                                        </div> 
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="modal-footer border-0">
                                                <button type="button" className="btn btn-light" onClick={closeModal}>Fechar</button>
                                                <button type="button" className="btn btn-success me-4" onClick={handlerSaveForm}>
                                                    Salvar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}